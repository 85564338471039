<template>
  <div>
    <div class="main-text top-line">
      This site is still under construction but you can contact us
      <router-link to="contact" target="_self" aria-haspopup="false">
        HERE
      </router-link>
    </div>
    <!--   
    <Carosel />

    <div class="main-text main"></div> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import Carosel from "../components/Carosel.vue";

export default {
  name: "Projects",
  // components: { Carosel },
};
</script>

<style scoped lang="scss">
.main {
  margin-bottom: 2rem;

  h2 {
    font-weight: 200;
    font-family: "Poppins", sans-serif;
    font-size: 4rem;
    margin: auto;
  }

  @media only screen and (max-width: 600px) {
    h2 {
      font-size: 6rem;
    }
  }

  .start {
    margin-right: 50rem;
    margin-bottom: -2rem;
    width: 35rem;
  }

  .end {
    margin-top: -2rem;
    margin-left: 35rem;
    width: 50rem;
  }

  .story {
    width: 60rem;
    margin: auto;
    text-align: left;
    font-size: 2rem;
  }
}
</style>
