<template>
  <div style="background-image: url('img/banner.jpeg'); padding: 6rem 0">
    <div class="main-text top-line">
      This site is still under construction but you can contact us
      <router-link to="contact" target="_self" aria-haspopup="false">
        HERE
      </router-link>
    </div>
    <div class="main-text blurb">
      <p>Sales pitch text goes here.</p>
      <p>
        Contrary to popular belief, Lorem Ipsum is not simply random text. It
        has roots in a piece of classical Latin literature from 45 BC, making it
        over 2000 years old. Richard McClintock, a Latin professor at
        Hampden-Sydney College in Virginia, looked up one of the more obscure
        Latin words, consectetur, from a Lorem Ipsum passage, and going through
        the cites of the word in classical literature, discovered the
        undoubtable source.
      </p>
    </div>
  </div>

  <div class="strap">
    <p>
      “Each of us is carving a stone, erecting a column, or cutting a piece of
      stained glass in the construction of something much bigger than
      ourselves.”
    </p>
    <p>– Adrienne Clarkson</p>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  components: {},
};
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  // height: 20rem;
  object-fit: cover;
  max-width: 100%;
  filter: sepia(0.2) hue-rotate(0deg);
}
// @media only screen and (max-width: 750px) {
//   .banner {
//     // width: 77rem;
//   }
// }

.blurb,
.top-line {
  font-size: 1.6rem;
  text-align: left;
  width: 65rem;
  margin: auto;
  background-color: rgba(59, 68, 75, 0.9);
  padding: 1rem 3rem;
}

.top-line {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.strap {
  font-weight: 200;
  // font-family: "", sans-serif;
  font-size: 3rem;
  width: 75rem;

  margin: auto;
  margin-top: 3rem;
  color: whitesmoke;

  p {
    margin: 0;
  }
}
</style>
