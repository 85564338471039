import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import TheBand from "../views/Projects.vue";
import Media from "../views/Media.vue";
import Video from "../views/Services.vue";
import News from "../views/News.vue";
import Contact from "../views/Contact.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/projects",
    name: "Projects",
    component: TheBand,
  },
  {
    path: "/media",
    name: "Media",
    component: Media,
  },
  {
    path: "/services",
    name: "Services",
    component: Video,
  },
  {
    path: "/news",
    name: "News",
    component: News,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
