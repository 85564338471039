<template>
  <div class="main-text top-line">
    This site is still under construction but you can contact us
    <router-link to="contact" target="_self" aria-haspopup="false">
      HERE
    </router-link>
  </div>

  <div class="main-text grid"></div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Services",
  components: {},
};
</script>
<style scoped lang="scss">
span {
  font-size: 2rem;
}
h2 {
  font-weight: 200;
  font-family: "Poppins", sans-serif;
  font-size: 6rem;
  margin: auto;
}

.grid {
  width: 60rem;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 5px 5px;

  img {
    width: 100%;
  }
}
</style>
