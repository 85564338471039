
import { Options, Vue } from "vue-class-component";
import TopBar from "./components/TopBar.vue";
import Title from "./components/Title.vue";
import MainMenu from "./components/MainMenu.vue";
import Footer from "./components/Footer.vue";

@Options({
  components: {
    TopBar,
    Title,
    MainMenu,
    Footer,
  },
})
export default class App extends Vue {}
